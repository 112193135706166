@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&family=Poppins:wght@100;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background: #F7F9FC;
}